.hero {
    position: relative;
    height: 780px;
    @include _768 {
        height: 720px;
    }

    &__inner {
        display: flex;
        position: relative;
    }

    &__main-img {
        position: absolute;
        height: 780px;
        right: 0;
        z-index: 0;
        width: calc((100vw - 1200px) / 2 + 1200px - 183px);
        @include _768 {
            height: 720px;
            width: 100%;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__aside {
        padding: 33px 33px 33px 0;
        width: 175px;
        @include _768 {
            background-color: #fff;
            position: fixed;
            top: 0;
            bottom: 0;
            left: -80vw;
            width: 80vw;
            padding: 0;
            z-index: 999;
            transition: left .15s ease;

            &.open {
                left: -20px;
            }
        }

        &-inner {
            display: flex;
            flex-direction: column;
            gap: 31px;
            align-items: center;
            @include _768 {
                padding: 33px 0;
                overflow-y: auto;
                height: 100%;
            }
        }

        .open-aside-mobile {
            display: none;
            @include _768 {
                background: #FFFFFF;
                border-radius: 0px 20px 20px 0px;
                display: block;
                position: absolute;
                top: 80px;
                left: 100%;
                height: 48px;
                width: 53px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.open {

                    .close {
                        display: block;
                    }

                    .arrow {
                        display: none;
                    }
                }

                .close {
                    display: none;
                }

                .arrow {
                    display: block;
                }
            }
        }
    }

    &__main {
        flex: 1;
        padding-top: 250px;
        padding-left: 17px;
        @include _768 {
            padding-left: 0;
            padding-top: 30vh;
        }
    }

    &__title {
        color: #fff;
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        margin-bottom: 15px;
        @include _768 {
            font-size: 30px;
            line-height: 36px;
            margin-bottom: 12px;
        }

        span {
            color: $color-primary;
        }
    }

    &__desc {
        color:#fff;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 57px;

        br {
            @include _768 {
                display: none;
            }
        }
    }

    &__actions {
        @include _768 {
            display: flex;
        }
    }
}