.wiki {
    margin-bottom: 90px;
    @include _768 {
        margin-bottom: 50px;
    }

    h2 {
        margin-bottom: 94px;
        @include _768 {
            margin-bottom: 36px;
            text-align: center;
        }
    }

    &__list {
        display: flex;
        gap: 20px;
        margin-bottom: 57px;
        @include _768 {
            flex-direction: column;
            gap: 50px;
            margin-bottom: 30px;
        }
    }

    &__action {
        text-align: center;

        .btn {
            min-width: 270px;
            @include _768 {
                width: 100%;
            }
        }
    }

    &--card {
        margin-right: -20px;
        margin-left: -20px;
    }

    .wiki-item {
        flex: 1;

        &__img {
            min-height: 260px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 13px;
            @include _768 {
                min-height: auto;
                margin-bottom: 40px;
            }
        }

        &__badge {
            display: flex;
            align-items: center;
            margin-bottom: 21px;

            img {
                flex: none;
                margin-right: 9px;
            }
        }

        &__date {

            .name {
                font-size: 14px;
                font-weight: 600;
                line-height: 17px;
                margin-bottom: 3px;
                text-transform: uppercase;
            }

            .date {
                color: #D9D9D9;
                font-size: 14px;
                font-weight: 600;
                line-height: 17px;
            }
        }

        &__tit {
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 20px;
            @include _768 {
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 14px;
            }
        }

        &__text {
            font-size: 18px;
            line-height: 21px;
            margin-bottom: 10px;
            @include _768 {
                font-size: 16px;
                line-height: 19px;
            }
        }

        &__link {
            color: #D9D9D9;
            font-size: 14px;
            font-weight: 600;
            text-decoration: underline;
            transition: color .15s ease;

            &:hover {
                color: #b1b1b1;
            }
        }
    }

    &--card {

        h2 {
            margin-bottom: 68px;
            @include _768 {
                margin-bottom: 30px;
            }
        }
    }
}