.about-hero {
    padding: 100px 0 87px;
    display: flex;
    font-size: 18px;
    line-height: 21px;
    @include _768 {
        padding-bottom: 50px;
        flex-direction: column;
    }

    &__img {
        flex: none;
        margin-right: 120px;
        @include _768 {
            margin-right: 0;
            margin-bottom: 24px;
            width: 50%;
        }
    }
}

.about-slider {
    margin-bottom: 100px;
    position: relative;
    padding: 0 55px;
    @include _768 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    &__inner {
        overflow: hidden;
        width: 100%;

        img {
            cursor: pointer;
            margin: 0 9px;
        }
    }

    .btn-control {
        position: absolute;
        top: 50%;

        &--left {
            right: 0;
        }

        &--right {
            left: 0;
        }
    }
}

.about-list {
    display: flex;
    gap: 25px;
    margin-bottom: 100px;
    @include _768 {
        margin-bottom: 50px;
        margin-top: 50px;
        flex-direction: column;
    }

    &__item {
        flex: 1;

        img {
            margin-bottom: 10px;
        }

        &-name {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 25px;
            line-height: 24px;
            @include _768 {
                font-size: 18px;
                margin-bottom: 10px;
            }
        }

        &-text {
            line-height: 19px;
        }
    }
}

.safe {
    display: flex;
    margin-bottom: 100px;
    @include _768 {
        margin-bottom: 50px;
        flex-direction: column;
        margin: 0 -20px;
    }

    &__img {
        margin-right: 20px;
        flex: none;
        @include _768 {
            margin-right: 0;
        }
    }

    &__content {
        padding-top: 15px;
        @include _768 {
            background-color: $color-primary;
            padding: 30px 20px 50px;

            .btn {
                color: #000;
                background-color: #fff;
                width: 100%;
            }
        }
    }

    &__title {
        color: $color-primary;
        font-size: 34px;
        font-weight: 700;
        line-height: 41px;
        margin-bottom: 11px;
        @include _768 {
            color: #fff;
            font-size: 24px;
            font-weight: 600;
            line-height: 29px;

            br {
                display: none;
            }
        }
    }

    &__text {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 38px;
        @include _768 {
            font-size: 16px;
        }
    }
}

.info {
    display: flex;
    margin-bottom: 100px;
    @include _768 {
        flex-direction: column;
        margin: 0 -20px 50px;
    }

    &__text {
        color: #fff;
        line-height: 41px;
        font-size: 34px;
        background-color: $color-primary;
        padding-top: 60px;
        padding-right: 63px;
        padding-left: calc((100vw - 1215px) / 2);
        margin-left: calc((100vw - 1215px) / -2);
        @include _768 {
            margin-left: 0;
            order: 2;
            font-size: 24px;
            line-height: 29px;
            padding: 20px 25px 50px;

            strong {
                font-size: 44px;
                display: block;
            }
        }

        strong {
            font-size: 38px;
        }
    }

    &__img {
        flex: none;
        @include _768 {
            order: 1;
        }
    }
}

.invite {
    margin-bottom: 30px;
    text-align: center;

    &__title {
        font-size: 34px;
        font-weight: 700;
        margin-bottom: 30px;
        @include _768 {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 20px;

            br {
                display: none;
            }
        }
    }

    &__desc {
        font-size: 18px;
        line-height: 21px;
        padding: 0 80px;
        @include _768 {
            padding: 0;
            text-align: left;
        }
    }
}

.about-form {
    background-color: #000;
    margin-bottom: 100px;
    padding: 60px 0 90px;
    color: #fff;
    @include _768 {
        padding-top: 0;
        padding-bottom: 50px;
        margin-bottom: 50px;
    }

    &__inner {
        display: flex;
        justify-content: center;
        @include _768 {
            flex-direction: column;
        }
    }

    &__img {
        margin-right: 117px;
        @include _768 {
            margin-right: 0;
            margin-bottom: 30px;
        }
    }

    &__title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        margin-bottom: 50px;
        @include _768 {
            font-weight: 600;

            br {
                display: none;
            }
        }
    }

    &__footer {
        font-size: 13px;
        line-height: 15px;
        margin-top: 32px;
        text-align: center;

        a {
            color: #fff;
            text-decoration: underline;
        }
    }

    form {
        margin: 0 auto;
        width: 285px;
    }

    input {
        background-color: transparent;
        border: none;
        color: #fff;
        border-bottom: 1px solid #fff;
        outline: none;
        font-size: 18px;
        width: 100%;

        &::placeholder {
            color: #fff;
        }
    }

    .form-row {
        margin-bottom: 45px;
    }

    .btn {
        width: 100%;
    }
}

.about-card-slider {
    display: none;
    @include _768 {
        display: block;
        margin-bottom: 50px;
        position: relative;
    }

    .slick-dots {
        padding: 0;
        list-style: none;
        margin-top: 20px;
        display: flex;
        justify-content: center;

        li {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        button {
            @include no-btn();
            font-size: 0;
            background-color: #000;
            border: none;
            padding: 0;
            border-radius: 50%;
            margin: 0 4px;
            height: 7px;
            width: 7px;
        }

        .slick-active button {
            background-color: $color-primary;
        }
    }

    .btn-interes {
        @include no-btn();
        position: absolute;
        top: 25%;
        z-index: 10;

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }
    }
}

.about-card {
    display: flex;
    margin-bottom: 100px;
    @include _768 {
        margin-bottom: 50px;
        display: none;
    }

    &__title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 50px;
        @include _768 {
            text-align: center;
        }
    }

    &__item {
        display: flex;
        width: 50%;
        @include _768 {
            flex-direction: column;
            width: 100%;
        }
    }

    &__img {
        margin-right: 50px;
        flex: none;
        @include _768 {
            margin-right: 0;

            img {
                margin: 0 auto;
            }
        }
    }

    &__content {
        padding-top: 30px;
    }

    &__name {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 7px;
        @include _768 {
            font-size: 18px;
            line-height: 22px;
        }
    }

    &__param {
        list-style: none;
        padding: 0;
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 5px;
        @include _768 {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 20px;
        }
    }

    &__action {

        a {
            color: #D9D9D9;
            font-size: 14px;
            font-weight: 600;
            text-decoration: underline;
        }
    }
}