$color-primary: #EA4033;
$color-primary-hover: #ff2313;

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	font-size: 16px;
    -webkit-text-size-adjust: none;

    &.no-scroll,
    &.no-scroll body {
        overflow: hidden;
    }
}

html,
body {
	min-height: 100%;
	position: relative;
}

body {
	font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

h2 {
    color: #000;
    font-size: 34px;
    font-weight: 700;
    line-height: 41px;
    @include _768 {
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
    }

    &.select {
        color: $color-primary;
    }
}

a {
    text-decoration: none;
}

img {
    display: block;
    max-width: 100%;
    margin: 0;
}

p {
    font-size: 18px;
    line-height: 22px;
    @include _768 {
        font-size: 16px;
        line-height: 19px;
    }
}

.main {
    padding-top: 90px;
    @include _768 {
        padding-top: 0;
    }
}

.checkbox {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1;
    @include _768 {
        font-size: 13px;
        color: #fff;
        line-height: 18px;
    }

    input[type="checkbox"] {
        display: none;

        &:checked + label:before {
            background-color: $color-primary;
        }

        &:checked + label:after {
            opacity: 1;
        }

        &:disabled + label {
            cursor: default;
            color: #D9D9D9;
        }
    }

    label {
        cursor: pointer;
        padding-left: 30px;
        position: relative;
        display: inline-block;
        @include _768 {
            padding-left: 22px;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 18px;
            width: 18px;
            border-radius: 3px;
            transition: background-color .15s ease;
            @include _768 {
                top: 0;
            }
        }

        &::before {
            background-color: #D9D9D9;
            @include _768 {
                background-color: #fff;
            }
        }

        &::after {
            background-image: url(../img/catalog/check.svg);
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0;
            transition: opacity .15s ease;
        }
    }
}

.no-btn {
    @include no-btn();

}

.btn-param {
    @include no-btn();
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;

    span {
        margin-right: 5px;
        text-decoration: underline;
    }
}

.btn {
    @include no-btn();
    color: #000;
    border-radius: 10px;
    background-color: #fff;
    font-size: 18px;
    font-weight: 500;
    min-width: 185px;
    padding: 19px 25px;
    margin: 0;
    text-align: center;
    @include _768 {
        min-width: 150px;
        font-size: 16px;
        padding: 19px;
    }

    &--primary {
        color: #fff;
        border: 1px solid $color-primary;
        background-color: $color-primary;
        transition: background-color .15s ease;

        &:hover {
            border-color: $color-primary-hover;
            background-color: $color-primary-hover;
        }
    }

    &--primary-outline {
        color: $color-primary;
        border: 1px solid;
        background-color: #fff;
        transition: background-color .15s ease;

        &:hover {
            background-color: #f3f3f3;
        }
    }

    &--outline {
        background-color: transparent;
        color: #fff;
        border: 1px solid;

        &:hover {
            background-color: #fff;
            color: $color-primary;
        }
    }

    &--fill {
        color:#000;
        border: 1px solid;
    }

    &--xs {
        font-size: 16px;
        padding: 10px 28px;
        min-width: auto;
    }

    & + .btn {
        margin-left: 20px;
        @include _768 {
            margin-left: 5px;
        }
    }
}

.container {
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
}

.nist {
    padding-top: 76px;
    margin-bottom: 90px;
    @include _768 {
        padding-top: 50px;
        margin-bottom: 50px;
    }

    &__inner {
        display: flex;
        @include _768 {
            flex-direction: column;
        }
    }

    &__img {
        flex: none;
        margin-right: 23px;
        @include _768 {
            width: 60%;
            margin: 0 auto;
            margin-bottom: 33px;
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        gap: 28px;
    }

    &__col {

        &:first-child {
            margin-right: 50px;
            @include _768 {
                margin-right: 0;
            }
        }
    }

    &__item {
        width: 350px;
        margin-bottom: 30px;
        @include _768 {
            width: 100%;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &.active {

            .icon-arrow {
                transform: rotate(180deg);
            }
        }

        &-head {
            display: flex;
            justify-content: space-between;

            .name {
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                margin-right: 30px;
            }

            .icon-arrow {
                cursor: pointer;
                box-shadow: none;
                margin-top: 5px;
                flex: none;
                height: 22px;
                width: 22px;
                border-radius: 3px;
                background-color: $color-primary;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 5px;
                transition: box-shadow .15s ease, transform .15s ease;

                &:hover {
                    box-shadow: 0 0 5px 1px $color-primary;
                }

                .icon {
                    display: block;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        &-content {
            padding-top: 15px;
            line-height: 19px;
            display: block;
            transition: max-height .15s;
        }
    }
}

.available {
    margin-bottom: 60px;
    @include _768 {
        margin-bottom: 50px;
    }

    &-list--main .available__item {
        justify-content: flex-end;

        span {
            margin-top: auto;
        }
    }

    h2 {
        margin-bottom: 63px;
        @include _768 {
            text-align: center;
            margin-bottom: 37px;
        }
    }

    &__list {
        display: flex;
        gap: 20px;
        @include _768 {
            flex-direction: column;
        }
    }

    &__item {
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
        height: 442px;
        padding-bottom: 33px;
        text-align: center;

        &:last-of-type {
            @include _768 {
                padding-bottom: 0;
            }
        }

        img {
            border-radius: 10px;
            flex: none;
            margin: 0 auto;
        }

        span {
            display: block;
            font-size: 18px;
            font-weight: 500;
            line-height: 1.3;
            margin-top: 15px;
            @include _768 {
                margin-top: 32px;
            }
        }

        a {
            position: absolute;
            opacity: 0;
            pointer-events: none;
            top: 147px;
            left: 50%;
            transform: translateX(-50%);
            transition: opacity .2s ease-in-out;
            @include _768 {
                position: relative;
                top: auto;
                opacity: 1;
                pointer-events: all;
                width: 100%;
                margin-top: 30px;
            }
        }

        .price {
            color: $color-primary;
            margin-top: 10px;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
        }

        &:hover {

            a {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    &--card {

        h2 {
            font-size: 24px;
        }
    }
}

.text-xl {
    font-size: 32px;
    font-weight: 500;
    line-height: 29px;
}

.about {
    padding-top: 62px;
    margin-bottom: 100px;
    @include _768 {
        padding-top: 0;
        margin-bottom: 50px;
    }

    &__inner {
        display: flex;
        @include _768 {
            flex-direction: column;
        }
    }

    &__text {
        padding-top: 19px;
        font-size: 18px;
        line-height: 21px;
        margin-right: 43px;
        max-width: 565px;
        @include _768 {
            margin-right: 0;
            font-size: 16px;
            line-height: 19px;
            width: 100%;
        }
    }

    &__slider {
        flex: none;
        position: relative;
        height: 426px;
        width: 390px;
        overflow: hidden;
        @include _768 {
            margin: 0 -20px;
            margin-top: 30px;
            width: calc(100% + 40px);
        }

        img {
            height: 426px;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 390px;
            @include _768 {
                width: 100%;
            }
            
            &:nth-child(1) {
                animation: image1 13s infinite;
                z-index: 10;
            }
            &:nth-child(2) {
                animation: image2 13s infinite;
                z-index: 9;
            }
            
            &:nth-child(3) {
                animation: image3 13s infinite;
                z-index: 8;
            }
        }
    }
}

.form {
    color: #fff;
    margin-bottom: 100px;
    @include _768 {
        margin-bottom: 50px;
    }

    &__inner {
        background-color: $color-primary;
        padding: 60px 0;
        padding-left: 205px;
        padding-right: 105px;
        @include _768 {
            padding: 37px 20px;
            margin: 0 -20px;
        }
    }

    &__head {
        display: flex;
        align-items: flex-end;

        img {
            flex: none;
            margin-left: 65px;
            margin-bottom: 20px;
            @include _768 {
                display: none;
            }
        }
    }

    &__title {
        font-size: 30px;
        font-weight: 700;
        line-height: 41px;
        @include _768 {
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            margin-bottom: 18px;
        }

        br {
            @include _768 {
                display: none;
            }
        }

        span {
            color: #000;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            @include _768 {
                font-weight: 700;
            }
        }
    }

    &__sub {
        color: #000;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin-top: 11px;
        @include _768 {
            font-size: 16px;
            margin-bottom: 26px;
            text-align: center;
        }
    }

    &__content {
        margin-bottom: 20px;
        display: flex;
        gap: 20px;
        align-items: center;
        @include _768 {
            flex-direction: column;
            gap: 25px;
        }

        & > * {
            flex: 1;
        }

        input {
            appearance: none;
            background-color: transparent;
            color: #fff;
            font-size: 18px;
            border: none;
            padding: 7px 0;
            outline: none;
            border-bottom: 1px solid #fff;
            width: 100%;
            @include _768 {
                font-size: 16px;
                width: 80%;
            }

            &::placeholder {
                color: #fff;
            }
        }

        .btn {
            @include _768 {
                width: 100%;
            }
        }
    }

    &__footer {
        font-size: 13px;
        @include _768 {
            text-align: center;
        }
        
        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    &--card {

        .form__inner {
            padding-left: 170px;
            padding-right: 170px;
            @include _768 {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        .form__head {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 43px;

            &-title {
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 13px;
                @include _768 {
                    text-align: center;
                }
            }

            &-desc {
                font-weight: 500;
                text-align: center;
            }
        }

        .form__content {
            padding-left: 20px;
            padding-right: 20px;

            .btn {
                flex: none;
            }
        }

        .form__footer {
            padding: 0 20px;
        }
    }
}

.gallery {
    margin-bottom: 100px;
    @include _768 {
        margin-bottom: 50px;
    }

    h2 {
        margin-bottom: 41px;
        @include _768 {
            text-align: center;
            margin-bottom: 36px;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 19px;
        @include _768 {
            display: none;
        }

        img {
            cursor: pointer;
        }
    }
}

.breadcrumbs {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    padding: 50px 0;
    @include _768 {
        padding-top: 100px;
        padding-bottom: 30px;
        font-size: 16px;
    }

    &__logo {
        margin-right: 11px;
        @include _768 {
            flex: none;
        }
    }

    &__active {
        color: $color-primary;
        @include _768 {
            display: block;
        }
    }

    a {
        color: #000;

        &:hover {
            text-decoration: underline;
        }
    }
}

.card-head {
    display: flex;
    margin-bottom: 100px;
    @include _768 {
        margin-bottom: 50px;
        flex-direction: column;
    }
}

.card-slider {
    margin-right: 50px;
    display: flex;
    width: 494px;
    @include _768 {
        flex-direction: column-reverse;
        margin-right: 0;
        width: 100%;
    }

    &__nav {
        flex: none;
        margin-right: 17px;
        width: 90px;
        position: relative;
        @include _768 {
            margin: 0 0 22px;
            width: 100%;
        }

        img {
            margin-bottom: 8px;
            @include _768 {
                margin: 0 8px;
                max-width: 95px;
            }
        }
    }

    &__next {
        @include no-btn();
        background-color: transparent;
        border: none;
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include _768 {
            display: none;
        }
    }

    &__preview {
        position: absolute;
        top: 33px;
        right: 24px;
        z-index: 1;
        pointer-events: none;
    }

    &__verified {
        position: absolute;
        left: 0;
        bottom: 8px;
        z-index: 1;
    }

    &__main {
        cursor: pointer;
        position: relative;
        height: 533px;
        width: 387px;
        overflow: hidden;
        @include _768 {
            height: auto;
            width: 100%;
            margin-bottom: 13px;
        }
    }
}

.card-title {
    font-size: 34px;
    font-weight: 700;
    line-height: 41px;
    margin-bottom: 24px;
    @include _768 {
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
    }
}

.card-logo-prod {
    margin-bottom: 29px;
    @include _768 {
        margin-bottom: 20px;
    }
}

.card-params {
    padding: 0;
    list-style: none;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 13px;
    @include _768 {
        font-size: 16px;
    }
    
    li {
        margin-bottom: 3px;
    }
}

.card-subinfo {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 54px;
    @include _768 {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 30px;

        br {
            display: none;
        }
    }
}

.card-action {
    @include _768 {
        display: flex;
        justify-content: space-between;

        .btn {
            font-size: 14px;
            padding: 15px 5px;

            &:last-child {
                flex: 1;
            }
        }
    }
}

.card-benefit {
    display: flex;
    gap: 20px;
    margin-bottom: 100px;
    @include _768 {
        margin-bottom: 50px;
        flex-direction: column;
        gap: 25px;
        align-items: baseline;
    }

    &__item {
        color: #fff;
        background-color: $color-primary;
        background-image: url(../img/logo-benefit.svg);
        background-repeat: no-repeat;
        background-size: auto;
        background-position: 93% 95%;
        border-radius: 10px;
        padding: 37px 15px 40px 55px;
        min-height: 220px;
        width: calc(100% / 3);
        @include _768 {
            min-height: 315px;
            width: 100%;
        }

        &-title {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 15px;
            line-height: 24px;
        }

        ul {
            font-size: 18px;
            line-height: 21px;
            padding-left: 20px;

            li {
                margin-bottom: 7px;
                font-size: 16px;
            }
        }
    }
}

.card-gallery {
    display: flex;
    gap: 25px;
    margin-bottom: 100px;
    padding: 0 48px;
    @include _768 {
        display: none;
    }

    &__column {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 25px;
    }
}

.card-gallery-mobile {
    display: none;
    @include _768 {
        display: block;
        margin-bottom: 50px;

        .gallery-slider-mob {
            margin: 0 auto;
        }

        &-slider {
            position: relative;

            .slick-dots {
                padding: 0;
                list-style: none;
                margin-top: 30px;
                display: flex;
                justify-content: center;

                li {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                button {
                    @include no-btn();
                    font-size: 0;
                    background-color: #000;
                    border: none;
                    padding: 0;
                    border-radius: 50%;
                    margin: 0 4px;
                    height: 7px;
                    width: 7px;
                }

                .slick-active button {
                    background-color: $color-primary;
                }
            }
        }
    }

    h2 {
        margin-bottom: 35px;
        text-align: center;
    }

    .btn-gallery {
        @include no-btn();
        position: absolute;
        top: 40%;

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }
    }
}

.card-tab {
    margin-bottom: 100px;
    @include _768 {
        margin-bottom: 50px;
    }

    .logo-mobile {
        display: none;
        @include _768 {
            display: block;
            width: 75%;
            margin: 0 auto 25px;
        }
    }
}

.tab-list {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #000;
    @include _768 {
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: flex-start;
        width: 100%;
    }

    &__item {
        cursor: pointer;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        padding: 7px 32px;
        position: relative;
        transition: color .15s ease;
        @include _768 {
            font-size: 16px;
            padding: 5px 15px;
        }

        &::after {
            content: '';
            position: absolute;
            width: 80%;
            left: 50%;
            transform: translateX(-50%);
            height: 8px;
            background-color: $color-primary;
            bottom: -5px;
            transform: translateX(-50%) scaleY(0);
            transition: transform .15s;
            @include _768 {
                bottom: -4px;
            }
        }

        &.active {
            color: $color-primary;

            &::after {
                transform: translateX(-50%) scaleY(1);
            }
        }
    }
}

.tab-content {
    padding-top: 85px;
    @include _768 {
        padding-top: 35px;
    }

    &__item {
        display: none;
        padding: 0 60px;
        @include _768 {
            padding: 0 15px;
        }

        &.active {
            display: block;
        }
    }

    &__main {
        overflow: hidden;
        max-height: 240px;

        &.open {
            max-height: 1500px;
        }
    }

    &__inner {

        &--flex {
            display: flex;
            align-items: flex-start;
            @include _768 {
                flex-wrap: wrap;
            }

            img {
                flex: none;
                margin-right: 60px;
                @include _768 {
                    margin: 0 auto 20px;

                    &.logo {
                        display: none;
                    }
                }
            }
        }

        ul, ol, p {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 15px;
        }

        li {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__footer {
        margin-top: 20px;
        height: 36px;
        position: relative;

        &.grad-hidden::before {
            display: none;
        }

        &:before {
            background: linear-gradient(180deg,hsla(0,0%,100%,0),#fff 79.69%);
            content: "";
            height: 75px;
            left: 0;
            pointer-events: none;
            position: absolute;
            top: -75px;
            width: 100%;
        }

        .btn-collapse {
            @include no-btn();
            border: 1px solid #d9d9d9;
            border-radius: 10px;
            background-color: #fff;
            height: 36px;
            display: flex;
            font-size: 14px;
            align-items: center;
            padding: 0 12px;

            &:hover {
                background-color: #f5f5f5;
            }
        }
    }
}

.catalog {
    display: flex;
    gap: 60px;
    margin-bottom: 100px;
    @include _768 {
        padding-top: 0;
    }
}

.catalog-content {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.catalog-inner {

    .paginator {
        margin-top: 50px;
    }
}

.paginator {
    display: flex;
    justify-content: center;

    &-content {
        margin: 0 25px;

        a {
            color: #000;
            font-size: 24px;
            font-weight: 500;
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }

            &.active {
                color: $color-primary;
            }
        }
    }
}

.catalog-item {
    border-radius: 5px;
    border: 2px solid #d9d9d9;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 284px;
    @include _768 {
        display: flex;
        padding: 20px 15px;
        width: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__img {
        margin-bottom: 15px;
        height: 240px;
        overflow: hidden;

        img {
            margin: 0 auto;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__name {
        font-weight: 500;
        line-height: 21px;
        margin-bottom: auto;
        text-align: center;
    }

    &__param {
        padding: 0;
        list-style: none;
        font-size: 14px;
        line-height: 17px;
        margin-top: 10px;
        margin-bottom: 15px;
    }

    &__action {
        text-align: center;
    }
}

.filter-mobile {
    display: none;
    @include _768 {
        display: inline-flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 25px;

        img {
            margin-right: 11px;
        }
    }
}

.filter-back,
.filter-logo {
    display: none;
    @include _768 {
        position: absolute;
    }
}

.filter-back {
    @include _768 {
        top: 56px;
        left: 25px;
        display: inline-flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;

        img {
            transform: rotate(90deg);
            margin-right: 10px;
        }
    }
}

.filter-logo {
    @include _768 {
        top: 28px;
        right: 21px;
        display: block;
    }
}

.filter {
    flex: none;
    width: 245px;
    @include _768 {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: #fff;
        width: 100vw;
        padding: 30px 25px;
        padding-right: calc(100vw - 180px);
        padding-top: 110px;
        z-index: 101;
        transition: opacity .15s ease-out;
        opacity: 0;
        pointer-events: none;

        &.show {
            opacity: 1;
            pointer-events: all;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: calc(100vw - 200px);
            background-color: $color-primary;
            z-index: -1;
        }
    }

    &__action {
        @include _768 {
            margin-top: 35px;
        }

        .btn {
            width: 100%;
            @include _768 {
                font-size: 16px;
                font-weight: 500;
                padding: 10px 5px;

                &--fill {
                    color: #858585;
                    font-size: 15px;
                    font-weight: 400;
                    padding: 0;
                    border: none;
                }
            }

            & + .btn {
                margin-left: 0;
                margin-top: 15px;
            }
        }
    }
}

.filter-sort {
    color: #000;
    display: block;
    margin-bottom: 15px;
    font-size: 18px;
}

.filter-section {
    margin-bottom: 40px;
    border-bottom: 2px solid #D9D9D9;
    padding-bottom: 26px;
    @include _768 {
        padding-bottom: 0;
        margin-bottom: 15px;
        border: none;
    }

    &__head {
        cursor: pointer;
        font-size: 15px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include _768 {
            font-weight: 400;
            line-height: 18px;
            padding: 5px 20px 5px 25px;
            margin: 0 -20px 0 -25px;

            img {
                display: none;
            }
        }

        &.active {
            @include _768 {
                background-color: $color-primary;
                color: #fff;
            }

            img {
                transform: rotate(180deg);
            }
        }
    }

    &__content {
        display: none;
        max-height: 240px;
        overflow-y: auto;
        margin-top: 17px;
        @include _768 {
            color: #fff;
            margin-top: -27px;
            max-height: none;
            position: absolute;
            left: 200px;
            bottom: 0;
            top: 140px;
            overflow-y: auto;
            width: calc(100vw - 200px);
            padding: 10px 8px 0 15px;
        }

        @include scrollbarItems();
    }
}

.filter-brands {
    display: flex;
    flex-direction: column;

    .filter-candela {
        order: 1;
    }
    .filter-sciton {
        order: 2;
    }
    .filter-lumenis {
        order: 3;
    }
    .filter-lutronic {
        order: 4;
    }
    .filter-ulthera {
        order: 5;
    }
    .filter-meditech {
        order: 6;
    }
    .filter-solta {
        order: 7;
    }
    .filter-classys {
        order: 8;
    }
    .filter-alma {
        order: 9;
    }
    .filter-cutera {
        order: 10;
    }
    .filter-syneron {
        order: 11;
    }
    .filter-zeltiq {
        order: 12;
    }
    .filter-cynosure {
        order: 13;
    }
}

.filter-badge {
    margin: 0 !important;
    min-height: auto !important;

    #mse2_selected {
        background-color: transparent;
        padding: 0;
        border-radius: 0;
    }

    &__item {
        color: #000;
        font-size: 14px;
        background-color: #D9D9D9;
        height: 21px;
        padding: 0 10px;
        display: inline-flex;
        align-items: center;
        border-radius: 3px;

        &:hover {
            text-decoration: line-through;
        }

        &:not(:first-child) {
            margin-left: 20px;
        }

        .filter-close {
            cursor: pointer;
            margin-left: 16px;
        }
    }

    & + .catalog-content {
        margin-top: 16px;
    }
}

.ginfo {
    width: 100%;

    &__row {
        display: flex;
        gap: 20px;
        width: 100%;
    }

    &__col {
        flex: 1;
        padding: 10px;
        padding-left: 0;

        &:first-child {
            border-bottom: 1px dotted #ddd;
            font-weight: 500;
        }
    }
}

@keyframes image1 {
    0% {
        transform: scale(1);
    }
    10% {
        transform: scale(1);
    }
    20% {
        transform: scale(0.3);
    }
    30% {
        transform: scale(0.3) translate(-50%, -25%);
    }
    35% {
        opacity: 1;
        transform: scale(0.5) translate(-220%, 0%);
    }
    36% {
        opacity: 0;
    }
    90% {
        opacity: 0;
        transform: scale(1);
    }
    95% {
        opacity: 0.5;
        transform: scale(0.3);
    }
    100% {
        opacity: 1;
        transform: scale(1) translate(0%, 0%);
    }
}

@keyframes image2 {
    0% {
        transform: scale(1);
    }
    10% {
        transform: scale(1);
    }
    20% {
        transform: scale(0.3);
    }
    30% {
        transform: scale(0.3) translate(0%, 0%);
    }
    35% {
        transform: scale(1) translate(0%, 0%);
        transform-origin: center center;
    }
    55% {
        transform: scale(1) translate(0%, 0%);
        transform-origin: 0% 80%;
    }
    60% {
        animation-timing-function: ease-out;
        transform: scale(0.3);
    }
    65% {
        transform: scale(0.3);
    }
    70% {
        transform: scale(0.3) translate(-250%, 0%);
        transform-origin: 0% 80%;
    }
    100% {
        transform: scale(0.3) translate(-250%, 0%); 
    }
}

@keyframes image3 {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    10% {
        transform: scale(1);
    }
    20% {
        transform: scale(0.3);
    }
    30% {
        transform: scale(0.3) translate(50%, 25%);
    }
    35% {
        height: 426px;
        transform: scale(0.5) translate(200%, 0%);
    }
    55% {
        height: 326px;
        transform: scale(0.5) translate(200%, 0%);
    }
    60% {
        transform: scale(0.6) translate(100px, 0%);
    }
    65% {
        height: 326px;
        transform: scale(0.6) translate(100px, 0%);
    }
    70% {
        height: 426px;
        transform: scale(1) translate(0%, 0%);
    }
    90% {
        opacity: 1;
        transform: scale(1) translate(0%, 0%);
    }
    95% {
        opacity: 0.5;
        transform: scale(0.3);
    }
    100% {
        opacity: 0;
        transform: scale(1) translate(0%, 0%);
    }
}