.params {
    color: #fff;
    background-color: #000;
    padding: 39px 0;
    margin-bottom: 100px;
    @include _768 {
        margin-bottom: 0;
    }

    &__inner {
        display: flex;
        gap: 40px;
        @include _768 {
            flex-direction: column;
            gap: 35px;
        }
    }

    &-col {
        width: 32%;
        @include _768 {
            width: 100%;
        }

        &--column {
            display: flex;
            flex-direction: column;
        }
    }

    &__action {
        margin-top: 20px;
        padding-left: 69%;
        @include _768 {
            padding-left: 0;
            margin-top: 35px;
        }
    }

    &-item {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-top: 25px;
        @include _768 {
            margin-top: 35px;
        }

        &:first-child {
            margin-top: 0;
        }

        span {
            color: $color-primary;
            font-weight: 700;
        }
    }

    &__head-1 {
        font-size: 25px;
        font-weight: 700;
        line-height: 30px;
        @include _768 {
            font-size: 20px;
            margin-bottom: 10px;
        }
    }

    &__head-2 {
        font-size: 25px;
        font-weight: 700;
        margin-top: -10px;
        margin-bottom: 20px;
        @include _768 {
            margin-bottom: 0;
        }

        span {
            color: $color-primary;
            font-size: 128px;
            font-weight: 700;
            @include _768 {
                font-size: 48px;
            }
        }
    }

    &__head-3 {
        display: flex;
        @include _768 {
            display: none;
        }

        img {
            margin-right: 13px;
        }

        span {
            margin-top: 30px;
        }
    }
}

.params-hidden-content {
    display: none;
    margin-top: 25px;
}